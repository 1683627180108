<template>
  <v-container fluid v-if="recipe" class="recipe-detail">
    <!-- TODO - BREADCRUMB  -->
    <v-row>
      <v-col cols="12" md="9">
        <div class="px-0 pt-0 d-flex align-center">
          <router-link
            class="d-flex align-center justify-center previous-page black--text"
            :to="{
              name: 'RecipeList'
            }"
          >
            <v-icon class="pr-2">$arrowBack</v-icon>
            <span class="h4">{{ $t("recipe.goBack") }}</span></router-link
          >
        </div>
        <h1 v-html="recipe.title.rendered" class="mt-3 mb-6"></h1>
        <p class="mt-n6 mb-4">
          {{ $t("posts.publishedOn") }}
          {{ $dayjs(recipe.modified).format("DD/MM/YYYY") }}
        </p>
        <p v-html="recipe.description[0]"></p>
        <div class="d-flex justify-space-between">
          <div class="parent-category primary--text font-weight-bold">
            <v-icon color="primary">$folder</v-icon>
            {{ $t("recipe.metas.recipes") }}
          </div>
          <div class="child-category primary--text font-weight-bold">
            <v-icon color="primary">$tag</v-icon> {{ recipe.course }}
          </div>
          <ShareButtons
            :post="recipe"
            direction="bottom"
            :extended="true"
          ></ShareButtons>
        </div>
        <v-img class="my-4" :src="recipe.featured_image_url"></v-img>
        <ul class="d-flex flex-wrap align-center justify-space-between my-2">
          <li class="d-inline-block mr-6 py-2" v-if="recipe.recipe_prep_time">
            <span class="text-subtitle-1">
              <v-icon class="primary--text pr-2 ">$alarm</v-icon
              >{{ $t("recipe.metas.prepTime") }}</span
            ><br />
            <span class="font-weight-bold text-h6">
              {{ recipe.recipe_prep_time }}
              {{ recipe.recipe_prep_time_text }}</span
            >
          </li>
          <li class="d-inline-block mr-6" v-if="recipe.servings">
            <span class="text-subtitle-1">
              <v-icon class="primary--text pr-2 ">$groups</v-icon
              >{{ $t("recipe.metas.servings") }}</span
            ><br />
            <span class="font-weight-bold text-h6">
              {{ recipe.servings }}
              {{ recipe.servings_type }}</span
            >
          </li>
          <li class="d-inline-block mr-6" v-if="recipe.rating">
            <span class="text-subtitle-1">
              <v-icon class="primary--text pr-2 ">$recipe</v-icon
              >{{ $t("recipe.metas.difficulty") }}</span
            ><br />
            <span class="font-weight-bold text-h6"> {{ recipe.rating }}</span>
          </li>
        </ul>
        <h2 class="mb-4">{{ $t("recipe.headings.preparing") }}</h2>
        <div v-html="recipe.content.unrendered"></div>
        <h2 class="mt-4">{{ $t("recipe.headings.ingredients") }}</h2>
        <v-list v-if="recipe.recipe_ingredients">
          <v-list-item
            v-for="(ingredient, index) in ingredients"
            :key="ingredient.ingredient_id"
            class="ingrediets-list"
          >
            <v-list-item-content>
              <IngredientCard v-bind:product="ingredient" />
              <v-divider v-if="index < ingredients.length - 1"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-row class="mb-4">
          <v-col cols="12" md="6">
            <v-btn
              outlined
              color="primary"
              class="recipe-btn bg-white"
              data-test="recipe-add-all-to-list"
              large
              depressed
              @click="addAllToFavorites"
            >
              {{ $t("recipe.detail.addAllProductToListBtn") }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn
              color="primary"
              class="recipe-btn"
              data-test="recipe-add-all-to-cart"
              large
              depressed
              @click="addAllToCart"
            >
              {{ $t("recipe.detail.addAllProductBtn") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3"
        ><PostSidebar :isRecipe="true"></PostSidebar>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <h4 class="mb-10">{{ $t("posts.mby_interest") }}</h4>
      <CardSlider :course="recipe.course" :isRecipe="true"></CardSlider>
    </v-row>
    <v-row no-gutters class="mt-10">
      <v-col>
        <PostFooter></PostFooter>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.previous-page:has(.v-icon) {
  text-decoration: none !important;
  > span {
    text-decoration: underline;
  }
}
.recipe-btn {
  width: 100%;
}
.recipe-tab {
  text-transform: initial !important;
}
.recipe-card.v-card {
  display: flex;
  flex-direction: column;
  // @media #{map-get($display-breakpoints, 'xs-only')} {
  //   flex-direction: column;
  // }
  .v-card__text {
    font-size: 1rem;
    white-space: break-spaces;
    .characteristics {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      .meal-course {
        color: white;
        background-color: var(--v-secondary-base);
        width: max-content;
      }
    }
  }
  .v-image {
    max-height: 300px;
  }
}
img.size-full {
  width: 100%;
}
.v-expansion-panel-header {
  padding: 16px 28px;
  font-size: 16px;
}
.v-expansion-panel-content__wrap {
  padding: 0 28px 16px;
}
.ingrediets-list .v-divider {
  width: 100%;
  .product.ingrediet-card {
    display: none;
  }
}
// h2 {
//   margin-top: 20px;
// }
@media #{map-get($display-breakpoints, 'xs-only')} {
}
</style>
<script>
import IngredientCard from "@/components/product/IngredientCard.vue";
import ListSelector from "@/components/lists/ListSelector.vue";

import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import productMixin from "~/mixins/product";
import login from "~/mixins/login";
import CMService from "~/service/cmService";
import ProductService from "~/service/productService";
import CartService from "~/service/cartService";
import ListService from "~/service/listService";
import get from "lodash/get";
import ShareButtons from "@/components/wordpress/ShareButtons.vue";
import PostSidebar from "@/components/wordpress/PostSidebar.vue";
import CardSlider from "@/components/wordpress/CardSlider.vue";
import PostFooter from "@/components/wordpress/PostFooter.vue";
import { mapActions } from "vuex";

export default {
  name: "RecipeDetail",
  mixins: [clickHandler, categoryMixins, productMixin, login],
  components: {
    IngredientCard,
    CardSlider,
    ShareButtons,
    PostSidebar,
    PostFooter
  },
  data() {
    return {
      content: null,
      recipe: null,
      products: null,
      ingredients: [],
      items: [],
      open: false
    };
  },
  // props: {
  //   postName: {
  //     type: String,
  //     default: null
  //   }
  // },
  mounted() {
    this.fetchRecipe(this.$route.params.postName);
    // this.fetchPageData(this.$route.params.postName);
  },
  computed: {
    rating() {
      return get(this.recipe, "rating");
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    async fetchRecipe(postName) {
      const res = await CMService.getCustomPostByFilters("recipe", [
        {
          filterName: "filter[recipe]",
          filterValue: postName
        }
      ]);

      if (res && res.data && res.data.length) {
        this.content = res.data;
        this.fetchPageData(this.content[0].id);
      }
    },
    async fetchPageData(recipeId) {
      const result = await CMService.getCustomPostByFilters(
        "recipe/" + recipeId
      );
      if (result) {
        this.recipe = result.data;
        const wpIngredients = this.recipe.recipe_ingredients[0];
        const ids = wpIngredients.map(product => Number(product.notes));
        const ingredients = await ProductService.getProductsByIds(ids);
        this.products = ingredients.products;
        console.log("PRODUCTS", this.products);
        for (const ingredient of wpIngredients) {
          let objIngr = ingredients.products.find(
            ({ productId }) => productId == ingredient.notes
          );
          if (objIngr) {
            objIngr.existsInShop = true;
            const quantity = CartService.plus(objIngr, 0, false);
            this.items.push({ product: objIngr, quantity });
          } else {
            objIngr = {};
            objIngr.existsInShop = false;
          }

          objIngr.wp_amount = ingredient.amount;
          objIngr.wp_unit = ingredient.unit;
          objIngr.wp_ingredient = ingredient.ingredient;
          this.ingredients.push(objIngr);
        }
      }
    },
    async addAllToCart() {
      // let qty;
      if (await this.needLogin("addtocart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            for (const product of this.products) {
              // if (product.productInfos.TIPOLOGIA === "Sfuso") {
              //   qty = product.productInfos.STEP_SELLING;
              // } else {
              //   qty =
              //     product.productInfos.QTY_MULT || product.productInfos.MINACQ;
              // }
              const quantity = CartService.plus(product, 0, false);
              await this.addProduct({ product, quantity });
            }
          }
        }
      }
    },
    async addAllToFavorites() {
      if (await this.needLogin("addtocart")) {
        let vm = this;
        let res = await vm.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: vm.$vuetify.breakpoint.smAndDown,
          width: 300
        });
        // let newQuantity = CartService.plus(vm.product, vm.quantity);
        if (res) {
          await ListService.addProductsToList(res.listId, this.items);
        }
      }
    },
    filterByCourse(course) {
      let query = {};
      query["course"] = course.slug;
      this.$router.replace({ query });
    }
  },
  watch: {
    "$route.params.postName"() {
      this.fetchRecipe(this.$route.params.postName);
    }
  },
  jsonld() {
    if (this.recipe) {
      const ingredients = this.recipe.recipe_ingredients[0].map(ingredient =>
        `${ingredient.amount} ${ingredient.unit} di ${ingredient.ingredient}`.trim()
      );

      // const recipeTimeTextMap = {
      //   min: "M",
      //   mins: "M",
      //   minute: "M",
      //   minutes: "M",
      //   minuti: "M",
      //   hour: "H",
      //   hours: "H",
      //   ora: "H",
      //   ore: "H"
      // };

      // const formattedPrepTimeText =
      //   recipeTimeTextMap[this.recipe.recipe_prep_time_text.toLowerCase()];

      // const formattedCookTimeText =
      //   recipeTimeTextMap[this.recipe.recipe_cook_time_text.toLowerCase()];

      // const prepTime = `PT${this.recipe.recipe_prep_time}${formattedPrepTimeText}`;
      // const cookTime = `PT${this.recipe.recipe_cook_time}${formattedCookTimeText}`;

      // const totalTime = `PT${this.$dayjs
      //   .duration(prepTime)
      //   .add(this.$dayjs.duration(cookTime))
      //   .toISOString()}`;

      const recipeInstructions = this.recipe.recipe_instructions[0];

      const recipeUrl = "https://basko.it/ricette/" + this.recipe.slug;

      const transformedInstructions = recipeInstructions.map(instruction => {
        return {
          "@type": "HowToStep",
          name: instruction.group,
          text: instruction.description,
          url: recipeUrl,
          image: instruction.image
        };
      });

      return {
        "@context": "https://schema.org/",
        "@type": "Recipe",
        name: this.recipe.title.rendered,
        image: this.recipe.featured_image_url,
        author: {
          "@type": "Organization",
          name: "Basko"
        },
        datePublished: this.$dayjs(this.recipe.date).format("YYYY-MM-DD"),
        description: this.recipe.raw_excerpt,
        // prepTime: prepTime,
        // cookTime: cookTime,
        // totalTime: totalTime,
        keywords: this.recipe.tags_name.join(","),
        recipeCategory: this.recipe.course,
        recipeCuisine: this.recipe.cuisine,
        recipeIngredient: ingredients,
        recipeInstructions: transformedInstructions,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: this.recipe.stars
        },
        video: {
          "@type": "VideoObject",
          name: this.recipe.title.rendered,
          description: this.recipe.raw_excerpt,
          thumbnailUrl: this.recipe.featured_image_url,
          embedUrl: this.recipe.video_url
        }
      };
    }
  },
  metaInfo() {
    if (this.recipe) {
      return {
        title: this.recipe.title.rendered,
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.path}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.recipe.title.rendered
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: this.recipe.title.rendered
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            property: "og:image:alt",
            content: this.recipe.title.rendered
          },
          {
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: this.recipe.title.rendered
          },
          {
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
